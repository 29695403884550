<template>
  <div>
    <v-alert
      elevation="6"
      type="error"
      class="mb-16"
      v-if="entities_messages.length >= maxMessageCount && !id"
    >
      You have reached maximum message count {{ maxMessageCount }}.
    </v-alert>
    <notification-box
      type="info"
      :flat="true"
      class="mb-4"
    >
      <div class="text-label">Important</div>
      <p>
        Any messages added on this page in any format should not be testamentary
        in nature. For example, do not refer to gifts made to any persons or
        entities. Messages added on this page that are inconsistent with the
        terms of your Will may revoke your Will in whole or in part.
      </p>
      <p>
        <strong>DO NOT</strong> include any sensitive information (eg. account
        logins or passwords).
      </p>
    </notification-box>
    <v-input
      :error-messages="
        showErrors.access && errorMessages.access ? errorMessages.access : null
      "
      class="mb-2"
      v-if="showErrors.access && errorMessages.access"
    />
    <div v-if="local">
      <div>
        <h3 class="mb-4">Title</h3>
        <v-text-field
          outlined
          v-model="local.title"
          :error-messages="
            showErrors.title && errorMessages.title ? errorMessages.title : null
          "
        />
      </div>

      <div>
        <h3 class="mb-4">Message</h3>
        <v-textarea
          outlined
          v-model="local.message"
          :error-messages="
            showErrors.message && errorMessages.message
              ? errorMessages.message
              : null
          "
        />
      </div>
      <div v-if="hasFile">
        <h3 class="mb-4">Uploaded file</h3>
        <transition-group
          name="component-fade"
          mode="out-in"
        >
          <v-list-item
            class="bg-grey-10 mb-1 radius-8"
            :key="localFormatted.file.name"
          >
            <v-list-item-content>
              <div class="font-weight-bold mb-2">
                {{ localFormatted.file.name }}
              </div>
              <div>
                {{ fileSize }} /
                {{ localFormatted.file.type }}
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                small
                icon
                class="white"
                @click="removeFile()"
                ><v-icon
                  size="22"
                  color="black"
                  >mdi-close</v-icon
                ></v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </transition-group>
      </div>
      <div v-else>
        <h3 class="mb-4">Add File</h3>
        <p>Maximum file size of 200MB.</p>
        <v-file-input
          v-bind="textField"
          v-model="fileUpload"
          placeholder="Browse..."
          accept="image/*, video/*, audio/*, application/pdf"
          show-size
        >
        </v-file-input>
      </div>
      <v-input
        :error-messages="
          showErrors.file && errorMessages.file ? errorMessages.file : null
        "
      />

      <h3 class="mb-4">Recipients:</h3>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!localFormatted.recipient.length"
          class="text-no-result"
          key="empty"
        >
          - Add at least one recipient -
        </div>
        <div
          key="list"
          v-else
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(item, index) in localFormatted.recipient"
              :key="item"
              class="mb-2"
              :id="item"
              type="person"
              @remove="removeRecipient(index)"
            />
          </transition-group>
        </div>
      </transition>
      <div class="mb-2">
        <btn-add-large
          label="Add Recipient"
          class="mb-2"
          @click="openDrawer = true"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.recipient && errorMessages.recipient
            ? errorMessages.recipient
            : null
        "
      />

      <v-input
        :error-messages="
          showErrors.access && errorMessages.access
            ? errorMessages.access
            : null
        "
        class="mb-2"
        v-if="showErrors.access && errorMessages.access"
      />

      <div class="d-flex align-center">
        <v-btn
          text
          @click="close"
          >{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          v-if="entities_messages.length < maxMessageCount || id"
          >Save</v-btn
        >
      </div>
    </div>
    <v-overlay v-if="messages_uploading_file">
      <v-card>
        <v-card-text>File is uploading...</v-card-text>
        <v-progress-linear
          v-model="messages_uploading_file"
        ></v-progress-linear>
      </v-card>
    </v-overlay>
    <drawer
      :open.sync="openDrawer"
      title="Add Recipient"
    >
      <drawer-person-select
        v-if="openDrawer"
        @select="addRecipient"
        :exclude="localFormatted.recipient"
      />
    </drawer>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from "lodash"
import { mapGetters } from "vuex"

import BtnAddLarge from "../../../components/ui/BtnAddLarge"
import NotificationBox from "../../../components/ui/NotificationBox"
import Drawer from "../../../components/wrapper/Drawer"
import DrawerPersonSelect from "../../../components/wrapper/DrawerPersonSelect"
import ItemPerson from "../../../components/wrapper/ItemPerson"
export default {
  name: "VaultFormsMessages",
  components: {
    NotificationBox,
    DrawerPersonSelect,
    ItemPerson,
    Drawer,
    BtnAddLarge,
  },
  props: {
    closeLabel: {
      default: "close",
    },
    id: {
      default() {
        return null
      },
    },
    accessStatus: {
      default: "activated",
    },
  },
  data: () => ({
    local: null,
    fileUpload: null,
    fileRemoved: false,
    openDrawer: false,
    showErrors: {
      recipient: false,
      title: false,
      message: false,
      file: false,
      access: true,
    },
    processing: false,
    maxMessageCount: 20,
  }),
  mounted() {
    this.local = cloneDeep(this.entity)
    this.local = {
      ...this.local,
      recipient: this.local.recipient
        ? typeof this.local.recipient === "string"
          ? [this.local.recipient]
          : this.local.recipient
        : [],
    }
  },
  methods: {
    async submit() {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        setTimeout(() => {
          this.close()
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      const docId = await this.$store.dispatch("account/save_entity", {
        id: this.id,
        entity: this.localFormatted,
        type: "message",
      })
      if (this.fileUpload)
        await this.$store.dispatch("account/upload_entity_message_file", {
          id: docId,
          file: this.fileUpload,
        })

      if (this.fileRemoved) {
        await this.$store.dispatch("account/remove_entity_message_file", {
          id: docId,
        })
        this.fileRemoved = false
      }
    },
    removeFile() {
      this.fileRemoved = true
      this.fileUpload = null
      this.$set(this.local, "file", {
        name: "",
        size: null,
        type: "",
      })
    },
    close() {
      this.$emit("close")
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showError[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    getFileData(file) {
      let type
      if (file.type.split("/")[0] === "image") {
        type = "image"
      } else if (file.type.split("/")[0] === "video") {
        type = "video"
      } else if (file.type.split("/")[0] === "audio") {
        type = "audio"
      } else {
        type = "document"
      }

      return {
        name: file.name,
        size: file.size,
        type: type,
      }
    },
    addRecipient(id) {
      this.local.recipient.push(id)
      this.openDrawer = false
    },
    removeRecipient(index) {
      this.$delete(this.local.recipient, index)
    },
  },
  computed: {
    ...mapGetters("account", ["messages_uploading_file", "entities_messages"]),
    entity() {
      const msg = this.$store.getters["account/entity"](this.id)
      return {
        title: msg.title,
        message: msg.message,
        recipient: msg.recipient,
        file: msg.file || {
          name: "",
          size: null,
          type: "",
        },
      }
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },

    errorMessages() {
      const msgs = {
        recipient: null,
        title: null,
        message: null,
        file: null,
        access: null,
      }

      if (this.accessStatus === "expired") {
        msgs.access = "To edit this message you will need to renew your account"
      }

      if (this.localFormatted.recipient.length === 0) {
        msgs.recipient = "Add at least one recipient"
      }
      if (!this.localFormatted.title) {
        msgs.title = "Required field"
      }

      if (this.localFormatted.message.length > 100000) {
        msgs.message = "Maximum length of 100,000 characters"
      }

      if (this.hasFile) {
        if (this.localFormatted.file.size > 200000000) {
          msgs.file = "Files must be under 200MB in size"
        }
      }

      if (!this.hasFile && !this.localFormatted.message) {
        msgs.file = msgs.message = "Message or file required"
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.entity)
    },
    localFormatted() {
      return {
        recipient: this.local?.recipient || [],
        title: this.local?.title?.trim() || "",
        message: this.local?.message?.trim() || "",
        file: this.fileUpload
          ? this.getFileData(this.fileUpload)
          : this.local?.file || null,
      }
    },
    hasFile() {
      return Boolean(
        (typeof this.localFormatted.file?.name !== "undefined" ||
          this.localFormatted.file?.name !== "") &&
          this.localFormatted.file?.name?.length
      )
    },
    fileSize() {
      const size =
        this.localFormatted.file && this.localFormatted.file.size
          ? Math.floor(this.localFormatted.file.size / (1000 * 1000))
          : 0

      return size === 0 ? "<1MB" : `~${size}MB`
    },
  },
}
</script>

<style lang="scss"></style>
