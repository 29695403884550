<template>
  <div>
    <v-list-item
      v-for="(item) in options"
      :key="item.value"
      @click="$emit('select', item.value)"
      link
      class="border-divider"
    >
      <v-list-item-content>
        <div class="font-weight-medium">{{ item.label }}</div>
      </v-list-item-content>
      <v-list-item-avatar size="24" color="#eee">
        <v-icon color="primary" size="18">mdi-plus</v-icon>
      </v-list-item-avatar>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'SelectList',
  props: {
    options: {
      default: () => ([]),
    },
  },
  data: () => ({
  }),
}
</script>

<style lang="scss">
</style>
