<template>
  <div>
    <div class="px-4">
      <p>Leave messages to share with your loved ones after you've passed.</p>
      <p>You can upload a document, video or photo with each message.</p>
      <div class="d-flex align-center py-2">
        <h3>Your Messages</h3>
        <v-spacer />
        <v-btn
          text
          class="bg-grey-20 radius-4"
          @click="openAdd"
          ><v-icon
            color="primary"
            left
            >mdi-plus-thick</v-icon
          >Add</v-btn
        >
      </div>
    </div>
    <div class="divider-light" />
    <transition
      name="component-fade"
      mode="out-in"
    >
      <div
        v-if="listMessages.length === 0"
        class="pa-4 border-divider-light"
        key="empty"
      >
        <div class="text-no-result">
          <div>
            Click the <strong>ADD</strong> button on the right to start leaving
            messages
          </div>
        </div>
      </div>
      <div
        v-else
        key="list"
      >
        <transition-group
          name="component-fade"
          mode="out-in"
        >
          <v-list-item
            :key="item.id"
            v-for="item in listMessages"
            class="border-divider-light"
            tile
          >
            <v-list-item-avatar
              class="bg-primary-10 align-self-start mt-6"
              size="36"
            >
              <v-icon
                size="22"
                color="black"
                >{{ messageIcon(item.file) }}</v-icon
              >
            </v-list-item-avatar>
            <v-list-item-content>
              <div class="font-weight-bold mt-2">
                {{ item.title }}
              </div>
              <div class="d-flex mt-2">
                <div class="font-weight-bold">For:</div>
                <ul
                  v-if="item.recipient.length"
                  style="list-style: none; padding-left: 0.5rem"
                >
                  <li
                    v-for="item in item.recipient"
                    :key="item"
                    class="mb-1"
                  >
                    {{ recipients[item].name }}
                  </li>
                </ul>
              </div>
            </v-list-item-content>
            <v-list-item-action class="fill-height">
              <div class="d-flex">
                <v-btn
                  text
                  small
                  class="bg-grey-30 py-4 mx-4 radius-4"
                  @click="editMessage(item.id)"
                  ><v-icon
                    left
                    color="primary"
                    >mdi-pencil-outline</v-icon
                  >Edit</v-btn
                >
                <v-btn
                  icon
                  color="black"
                  height="32"
                  class="bg-grey-30 radius-4"
                  @click="openDeleteDialog(item.id)"
                  ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                >
              </div>
            </v-list-item-action>
          </v-list-item>
        </transition-group>
      </div>
    </transition>
    <dialog-full
      :open.sync="dialogOpen"
      :title="messageId ? 'Edit Message' : 'Add Message'"
      path="/messages"
      base-path="/vault"
      persistent
    >
      <component
        v-if="openForm"
        :is="`vault-forms-${openForm}`"
        :id="messageId"
        :access-status="this.accessStatus"
        @close="close"
      />
    </dialog-full>
    <v-dialog
      v-model="maxCountAlert"
      :max-width="500"
      persistent
    >
      <v-card>
        <v-list-item
          dense
          class="bg-sand"
        >
          <v-list-item-avatar class="mr-2">
            <v-icon color="warning">mdi-alert</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <h3 class="dialog-title">Warning</h3>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              @click="maxCountAlert = false"
              ><v-icon color="black">mdi-close</v-icon></v-btn
            >
          </v-list-item-action>
        </v-list-item>
        <v-list-item class="py-4">
          <v-list-item-content>
            <div class="font-weight-bold mb-4">Messages</div>
            <div class="mb-4">
              You have reached maximum message count {{ maxMessageCount }}.
            </div>
          </v-list-item-content>
        </v-list-item>
        <div class="divider" />
        <v-btn
          text
          tile
          block
          x-large
          class="bg-grey-10"
          @click="maxCountAlert = false"
          >OK</v-btn
        >
      </v-card>
    </v-dialog>
    <dialog-small
      title="Delete Message"
      :open.sync="openDeleteMessage"
      @close="
        () => {
          this.messageId = null
          this.openDeleteMessage = false
        }
      "
    >
      <div class="pa-4">
        <div>Do you want to delete the message?</div>
      </div>
      <template v-slot:buttons>
        <div class="divider" />
        <v-btn
          text
          tile
          x-large
          block
          @click="deleteMessage(messageId)"
          ><v-icon
            left
            color="error"
            >mdi-trash-can-outline</v-icon
          >Delete</v-btn
        >
      </template>
    </dialog-small>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import NotificationBox from "../../../components/ui/NotificationBox"
import DialogFull from "../../../components/wrapper/DialogFull"
import DialogSmall from "../../../components/wrapper/DialogSmall"
import VaultFormsMessages from "../forms/Messages"
import mixins from "./mixins"
export default {
  name: "VaultMessages",
  mixins: [mixins],
  components: {
    VaultFormsMessages,
    DialogFull,
    DialogSmall,
    NotificationBox,
  },
  data: () => ({
    openForm: null,
    messageId: null,
    maxCountAlert: false,
    maxMessageCount: 20,
    openDeleteMessage: false,
  }),
  methods: {
    editMessage(id) {
      this.messageId = id
      this.openForm = "messages"
    },
    openDeleteDialog(id) {
      if (this.accessStatus !== "active") {
        this.$emit("accessDenied")
        return
      }

      this.messageId = id
      this.openDeleteMessage = true
    },
    async deleteMessage() {
      await this.$store.dispatch("account/delete_entity", this.messageId)
      this.openDeleteMessage = false
      this.messageId = null
    },
    close() {
      this.openForm = null
      this.messageId = null
    },
    openAdd() {
      if (this.accessStatus !== "active") {
        this.$emit("accessDenied")
        return
      }
      if (this.entities_messages.length >= this.maxMessageCount) {
        this.maxCountAlert = true
        return
      }
      this.openForm = "messages"
      this.messageId = null
    },
    messageIcon(file) {
      if (file && file.type && file.type.length > 0) {
        if (file.type === "document") return `mdi-file-document-outline`
        if (file.type === "video") return `mdi-video-outline`
        if (file.type === "audio") return `mdi-ear-hearing`
        if (file.type === "image") return `mdi-image-outline`
        return "mdi-paperclip"
      }

      return "mdi-format-text"
    },
  },
  computed: {
    ...mapGetters("account", ["entities_messages"]),
    listMessages() {
      return this.entities_messages.map((message) => {
        return {
          id: message.id,
          title: message.title,
          recipient:
            typeof message.recipient === "string"
              ? [message.recipient]
              : message.recipient,
          file: message.file,
        }
      })
    },
    dialogOpen: {
      get() {
        return Boolean(this.openForm)
      },
      set(val) {
        if (!val) {
          this.openForm = null
          this.messageId = null
        }
      },
    },
    recipients() {
      let recipients = {}
      this.entities_messages
        .flatMap((x) => x.recipient)
        .forEach((v) => {
          recipients[v] = this.$store.getters["account/entity"](v)
        })

      return recipients
    },
  },
}
</script>

<style lang="scss">
.will-step-icon {
  background-color: var(--primary-10);
}
.dialog-title {
  font-family: CooperBT;
}
</style>
